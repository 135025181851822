import axios from 'axios'

import { contactFormSetup } from './contact'
import { technologiesMosaicSetup } from './technologiesMosaic'
import { mediaModalSetup } from './mediaModal'

contactFormSetup()
technologiesMosaicSetup()
mediaModalSetup()

