function mediaModalSetup() {
/**
 * Gallery modal to view media in large
 */
const mediaDataRaw = document.getElementById('project-images-data')
if (mediaDataRaw == null) return;
const mediaData = JSON.parse(mediaDataRaw.textContent)
console.log('mediaData', mediaData)


let mediaModal = document.querySelector('#media-modal')
let mediaModalContent = document.querySelector('#media-modal-content')
// let mediaModalImage = document.querySelector('#media-modal img')
// let mediaModalVideo = document.querySelector('#media-modal video')
// let mediaModalSource = document.querySelector('#media-modal video source')

// function disableScroll() { 
//     // Get the current page scroll position 
//     scrollTop = window.pageYOffset || document.documentElement.scrollTop; 
//     scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, 
  
//         // if any scroll is attempted, set this to the previous value 
//         window.onscroll = function() { 
//             window.scrollTo(scrollLeft, scrollTop); 
//         }; 
// } 
  
// function enableScroll() { 
//     window.onscroll = function() {}; 
// }
let modalCaption = document.getElementById('caption')
let media = {}

let fillModal = (m) => {
  mediaModalContent.innerHTML = ""
  let attr = document.createAttribute('src')
  attr.value = m.image
  let el = null
  el = document.createElement('img')
  el.attributes.setNamedItem(attr)
  mediaModalContent.appendChild(el)
  modalCaption.innerText = m.description
}

setTimeout(() => {
  mediaModal.style = ''
}, 500)

window.openModal = (id) =>  {

  mediaModal.style.visibility = 'visible'
  mediaModal.style.opacity = 1

  media = mediaData.filter(m => m.id === id)[0]

  fillModal(media)

  //document.body.style.height = '100vh'
  document.body.style.overflow = 'hidden'
  document.body.style.touchAction = 'none'

  setTimeout(() => {

    // const outsideClickListener = event => {
    //   if (!mediaModalContent.contains(event.target) && isVisible(mediaModalContent)) {
    //     closeModal()
    //     document.removeEventListener('click', outsideClickListener)
    //   }
    // }
    // document.addEventListener('click', outsideClickListener)
  
  }, 100)
}

const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )

window.closeModal = () =>  {
  mediaModal.style.visibility = 'hidden'
  mediaModal.style.opacity = 0
  document.body.style.overflow = 'initial'
  document.body.style.height = 'initial'
  document.body.style.touchAction = 'initial'
}

window.navLeft = () => {
  if (mediaData.indexOf(media) === 0) { return; }
  media = mediaData[mediaData.indexOf(media) - 1]
  fillModal(media)
}

window.navRight = () => {
  if (mediaData.indexOf(media) + 1 === mediaData.length) { return; }
  media = mediaData[mediaData.indexOf(media) + 1]
  fillModal(media)
}

}

export { mediaModalSetup };
