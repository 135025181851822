import axios from 'axios'

function contactFormSetup() {
    /**
     * Contact form javascript implementation
     */
    const namespace = "lefuturiste"
    const postURL = "https://contact-form.thingmill.fr"

    const form = document.getElementById('contact-form')
    if (form == null) return;

    const formName = document.getElementById('contact-form-name')
    const formEmail = document.getElementById('contact-form-email')
    const formSubject = document.getElementById('contact-form-subject')
    const formMessage = document.getElementById('contact-form-message')

    const formSubmit = document.getElementById('contact-form-submit')
    const formButtonLoading = document.getElementById('contact-form-loading')
    const successAlert = document.getElementById('contact-alert-success')
    const errorAlert = document.getElementById('contact-alert-error')

    form.onsubmit = (e) => {
        e.preventDefault()
        console.log('> Attempting to send a message', formName.value, formEmail.value, formMessage.value)
        let savedButtonText = formSubmit.textContent;
        formButtonLoading.style.display = 'block'
        formSubmit.style.display = 'none'
        axios.post(postURL + '/' + namespace, {
            name: formName.value,
            email: formEmail.value,
            subject: formSubject.value,
            message: formMessage.value
        }).then(() => {
            console.log('> Message sent')
            successAlert.style.display = "block";
            formButtonLoading.style.display = 'none'
            formSubmit.style.display = 'block'
            formSubmit.textContent = savedButtonText;
            
            // reset the form?
            // formName.value = ''
            // formEmail.value = ''
            // formSubject.value = ''
            // formMessage.value = ''
        }).catch((err) => {
            errorAlert.style.display = "block";
            console.error(err)
        })
    }
}

export { contactFormSetup }

