let opened = false

function technologiesMosaicSetup() {
    const mosaic = document.getElementById('tech-mosaic')
    const mosaicContainer = document.getElementById('tech-mosaic-container')
    const btnContainer = document.getElementById('tech-mosaic-btn-container')

    window.openTechMosaic = () => {
        if (!opened) {
          mosaicContainer.className = mosaicContainer.className.replace(' closed', ' opened')
        } else {
          mosaicContainer.className = mosaicContainer.className.replace(' opened', ' closed')
        }
        opened = !opened
    }
}

export { technologiesMosaicSetup }

